import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import PageWrapper from '../../components/PageWrapper';
import ActivityItem from '../../components/ActivityItem';
import ActivityWrapper from '../../components/ActivityWrapper';
import ModalTitle from '../../components/UI/DialogTitle';
import { Stack, Typography, Grid, DialogContent, Dialog, Box, Link } from '@mui/material';
import { Button } from '../../components/UI/Button';
import PublicIcon from '@mui/icons-material/Public';
import LogoutIcon from '@mui/icons-material/Logout';
import ProfilePhotoTiles from './ProfilePhotoTiles';
import { logout } from '../auth/slice';
import { useHistory } from 'react-router-dom';

const Profile = () => {
  const {user} = useSelector(store => store.user);
  const [openLogOutModal, setOpenLogOutModal] = useState(false);
  const history = useHistory();

  return (
    <PageWrapper>
      <Stack direction={'column'} justifyContent={{xs: 'flex-start', sm: 'space-between'}} sx={{flexGrow: 1}}>
        <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'space-between'} sx={{mb: 3}}>
          <Typography sx={{fontSize: 18, fontWeight: 400}}>Profile</Typography>
          <Button
            onClick={() => history.push('/edit-profile')}
            variant={'text'}
            sx={{height: 32, textDecoration: 'underline', textTransform: 'capitalize'}}
          >
            Edit
          </Button>
        </Stack>
        {user && (
          <Box sx={{maxWidth: 500, margin: '0 auto'}}>
            <Stack direction={'column'} alignItems={'center'} sx={{ mx: 'auto'}}>
              {user?.role !== 'admin' && user?.imgUrl?.length > 0 && (
                <Box sx={{
                  borderRadius: '16px',
                  background: '#F8F8F8',
                  backdropFilter: 'blur(2px)',
                  p: 2,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                  <ProfilePhotoTiles isUser />
                </Box>
              )}
              <Typography sx={{fontSize: "16px", color: "#373632", fontWeight: "600", mb: 1.2}}>{user?.username}</Typography>
              <Stack direction={'row'} alignItems={'center'} sx={{mb: 1.2}}>
                <Typography sx={{fontSize: "14px", color: "#696969"}}>{user?.email}</Typography>
              </Stack>
              {Boolean(user?.instagram) && (
                <Link href={`https://instagram.com/${user.instagram}`} target={'_blank'} sx={{textTransform: 'lowercase', fontWeight: 300, fontSize: "14px", color: "#696969", mb: 2}}>@{user.instagram}</Link>
              )}
              <Typography
                sx={{
                  maxWidth: 250,
                  width: '100%',
                  fontWeight: 'normal',
                  fontSize: 16,
                  textAlign: "center",
                  lineHeight: "24px",
                  mb: 3,
                  display: '-webkit-box',
                  WebkitLineClamp: '3',
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden'
                }}
              >
                {user?.shortBio}
              </Typography>
              {user?.role !== 'admin' && (
                <>
                  <Stack direction={'row'} alignItems={'center'} sx={{my: 1.5}}>
                    <PublicIcon sx={{color: "#696969", fontSize: 18, mr: 0.5}} />
                    <Typography sx={{fontSize: 14, color: "#696969"}}>
                      The information below will be included in applications.
                    </Typography>
                  </Stack>
                  <Grid container spacing={1}>
                    {Boolean(user?.gender) && (
                      <>
                        <Grid item xs={6}>
                          <Typography sx={{fontWeight: 500}}>Gender</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                          <Typography sx={{textTransform: 'capitalize', fontWeight: 300}}>{user?.gender}</Typography>
                        </Grid>
                      </>
                    )}
                    {Boolean(user?.birthDate)&& (
                      <>
                        <Grid item xs={6}>
                          <Typography sx={{fontWeight: 500}}>Date of birth</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                          <Typography sx={{fontWeight: 300}}>{dayjs(user?.birthDate).format("DD/MM/YYYY")}</Typography>
                        </Grid>
                      </>
                    )}
                    {Boolean(user?.occupationSector) && (
                      <>
                        <Grid item xs={6}>
                          <Typography sx={{fontWeight: 500}}>Industry</Typography>
                        </Grid>
                        <Grid item xs={6} sx={{display: 'flex', justifyContent: 'flex-end'}}>
                          <Typography sx={{textTransform: 'capitalize', fontWeight: 300}}>{user?.occupationSector}</Typography>
                        </Grid>
                      </>
                    )}
                    {Boolean(user?.interests) && Boolean(user?.interests?.length) && (
                      <>
                        <Grid item xs={6}>
                          <Typography sx={{fontWeight: 500}}>Interests</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <ActivityWrapper sx={{justifyContent: 'flex-end'}}>
                            {user?.interests?.map(interest => <ActivityItem value={interest} key={interest} />)}
                          </ActivityWrapper>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Stack>
          </Box>
        )}
        <Stack direction={'row'} justifyContent={'flex-end'} sx={{pt: 2, mt: {xs: 'auto', sm: 0}}}>
          <Button
            variant={'outlined'}
            sx={{height: 32, '&.MuiButton-outlined': {border: 'none', px: 0, textDecoration: 'underline'}}}
            startIcon={<LogoutIcon sx={{color: '#0E1D31'}} />}
            onClick={() => setOpenLogOutModal(true)}
          >
            log out
          </Button>
        </Stack>
      </Stack>
      <LogOutModal open={openLogOutModal} onClose={() => setOpenLogOutModal(false)} />
    </PageWrapper>
  );
};

export default Profile;

export const LogOutModal = ({open, onClose}) => {
  const dispatch = useDispatch();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: 500,
          width: '100%',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <ModalTitle onClose={onClose}>
        <Typography sx={{fontSize: 15, fontWeight: 600}}>Do you want to log out?</Typography>
      </ModalTitle>
      <DialogContent sx={{px: 3, py: 5, mt: 5, display: 'flex', alignItems: 'center', gap: 3}}>
        <Button
          fullWidth
          variant="outlined"
          color="error"
          onClick={onClose}
        >
          No
        </Button>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => dispatch(logout())}
        >
          Yes
        </Button>
      </DialogContent>
    </Dialog>
  );
};
