import Label from '../../components/UI/Label';
import Select from '../../components/UI/Select';
import { Grid, MenuItem } from '@mui/material';
import SectorsSelect from '../../components/SectorsSelect';
import ActivitiesGroup from '../../components/ActivitiesGroup';
import { Button } from '../../components/UI/Button';
import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useUpdateProfileMutation } from '../../services/mainApi';
import dayjs from 'dayjs';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import ProfilePhotoTiles from './ProfilePhotoTiles';
import Input from '../../components/UI/Input';
import CustomDateField from '../../components/CustomDateField';
import Loader from '../../components/Loader';
import ConnectInstagramButton from './ConnectInstagramButton';

const ProfileForm = memo(({onSuccess = () => {}}) => {
  const {user} = useSelector(store => store.user);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const isDisabled = form?.gender === "" || form?.occupationSector === "" || form?.shortBio === "" ||
    form?.birthDate === "" || form?.instagram === "" || !form?.interests?.length;
  const isChanged = JSON.stringify(form) === JSON.stringify({
    shortBio: user?.shortBio || '',
    gender: user?.gender || '',
    birthDate: user?.birthDate || null,
    interests: user?.interests || [],
    occupationSector: user?.occupationSector || '',
    instagram: user?.instagram || '',
  });
  const [update, {isLoading: isUpdateLoading}] = useUpdateProfileMutation();

  useEffect(() => {
    if (user?.role) {
      setForm({
        shortBio: user?.shortBio || '',
        gender: user?.gender || '',
        birthDate: user?.birthDate || null,
        interests: user?.interests || [],
        occupationSector: user?.occupationSector || '',
        instagram: user?.instagram || '',
      })
    }
  }, [user?.shortBio, user?.gender, user?.birthDate, user?.interests, user?.occupationSector, user?.instagram, user?.role ]);

  const onChangeInput = ({target: {name, value}}) => {
    setErrors(state => ({...state, [name]: false}))
    if (name === 'shortBio' && value.length > 300) {
      setErrors(state => ({...state, [name]: 'Field should contain less than 300 characters'}))
      setForm(state => ({...state, [name]: value.slice(0, 300)}));
      return;
    }
    setForm(state => ({...state, [name]: value}));
  };

  const handleDateChange = (e) => {
    setForm(state => ({...state, birthDate: dayjs(e?.$d || null).format()}))
  };

  const onChangeActivity = (name) => (value) => {
    setForm(state => ({
      ...state,
      [name]: state[name].includes(value) ? state[name].filter(item => item !== value) : [...state[name], value]
    }));
  };

  const onConnectInstagram = (username) => {
    setForm(state => ({...state, instagram: username}));
  };

  const onUpdate = () => {
    update({id: user?.id, body: form}).then(res => {
      if (res.data) {
        enqueueSnackbar(`You have successfully updated your profile`, {
          variant: 'info',
          autoHideDuration: 10000,
          preventDuplicate: true,
          key: 'not_found',
          action: key => <IconButton
            onClick={() => closeSnackbar(key)}
          >
            <CloseIcon sx={{fontSize: '20px'}}/>
          </IconButton>
        });
        onSuccess();
      }
    });
  };

  return (
    <Grid container rowSpacing={2} columnSpacing={3}>
      <Grid item xs={12}>
        <ProfilePhotoTiles showActions sx={{maxWidth: '100%'}} isUser />
      </Grid>
      <Grid item xs={12}>
        <Label sx={{mb: 1}}>Short bio</Label>
        <Input
          required={user?.role === 'guest'}
          fullWidth
          multiline
          rows={4}
          name={'shortBio'}
          value={form?.shortBio || ''}
          error={errors?.shortBio}
          onChange={onChangeInput}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Label sx={{mb: 1}}>Gender</Label>
        <Select
          name={'gender'}
          value={form?.gender || ''}
          onChange={onChangeInput}
          error={errors?.gender}
        >
          <MenuItem value={"male"}>Male</MenuItem>
          <MenuItem value={"female"}>Female</MenuItem>
          <MenuItem value={"other"}>Other</MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Label sx={{mb: 1}}>Date of Birth</Label>
        <CustomDateField
          name={'birthDate'}
          value={dayjs(form?.birthDate) || null}
          onChange={handleDateChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Label sx={{mb: 1}}>Industry</Label>
        <SectorsSelect
          name={'occupationSector'}
          value={form?.occupationSector || ''}
          onChange={onChangeInput}
          error={errors?.occupationSector}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Label sx={{mb: 1}}>Instagram</Label>
        <Input
          name={'instagram'}
          value={form?.instagram || ''}
          onChange={onChangeInput}
          placeholder={'@username'}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={12} sx={{display: 'flex', justifyContent: 'center'}}>
        <ConnectInstagramButton isConnected={form?.instagram} onSuccess={onConnectInstagram} />
      </Grid>
      <Grid item xs={12}>
        <ActivitiesGroup
          selectedActivities={form?.interests}
          label={'Interests'}
          required={user?.role === "host"}
          tooltipText={'Things you enjoy'}
          error={errors?.interests}
          onChange={onChangeActivity('interests')}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          variant={'contained'}
          sx={{'&.MuiButton-contained':{maxWidth: 330, width: '100%', mx: 'auto', display: 'block'}}}
          onClick={onUpdate}
          disabled={isDisabled || isChanged}
        >
          Update
        </Button>
      </Grid>
      {isUpdateLoading && <Loader />}
    </Grid>
  )
});

export default ProfileForm;
