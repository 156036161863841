import { Badge, Box, Grid, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';
import AppsIcon from '@mui/icons-material/Apps';
import CelebrationIcon from '@mui/icons-material/Celebration';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useSelector } from 'react-redux';
import LoginIcon from '@mui/icons-material/Login';
import HideOnScroll from './HideOnScroll';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PersonIcon from '@mui/icons-material/Person';
import React from 'react';
const MobileNav = () => {
  const {user, notifications} = useSelector(store => store);

  return (
    <HideOnScroll slideDirection={'up'}>
      <Box sx={{display: {xs: 'block', sm: 'none'}, position: 'fixed', bottom: 0, left: 0, width: '100%', background: '#fff', py: 1, zIndex: 2}}>
        <Grid container spacing={user?.authenticated ? 1 : 0} justifyContent={'center'} alignItems={'center'} sx={{height: 60}}>
          {user?.authenticated && (<>
            <Grid item xs={2.4}>
              <Link
                component={NavLink}
                to={'/parties-gallery'}
                sx={LinkSx}
              >
                <AppsIcon />
              </Link>
            </Grid>
            {user?.user?.role !== 'admin' && (
              <Grid item xs={2.4}>
                <Link
                  component={NavLink}
                  to={'/my-parties'}
                  sx={LinkSx}
                >
                  <CelebrationIcon/>
                </Link>
              </Grid>
            )}
            {user?.user?.role === 'admin' && (
              <Grid item xs={2.4}>
                <Link
                  component={NavLink}
                  to={'/user-management'}
                  sx={LinkSx}
                >
                  <ManageAccountsIcon/>
                </Link>
              </Grid>
            )}
            {/*<Grid item xs={3}>*/}
            {/*  <Link*/}
            {/*    component={NavLink}*/}
            {/*    to={'/my-applications'}*/}
            {/*    sx={LinkSx}*/}
            {/*  >*/}
            {/*    <EventIcon />*/}
            {/*    <Typography align={'center'}>applications</Typography>*/}
            {/*  </Link>*/}
            {/*</Grid>*/}
            <Grid item xs={2.4}>
              <Link
                component={NavLink}
                to={'/inbox'}
                sx={LinkSx}
              >
                <MailOutlinedIcon />
              </Link>
            </Grid>
            <Grid item xs={2.4}>

              <Link
                component={NavLink}
                to={'/notifications'}
                sx={LinkSx}
              >
                <Badge badgeContent={notifications?.newNotificationsCount} color="primary" sx={{'& .MuiBadge-badge': {color: '#fff'}}}>
                  <NotificationsIcon />
                </Badge>
              </Link>
            </Grid>
            <Grid item xs={2.4}>
              <Link
                component={NavLink}
                sx={LinkSx}
                to={'/profile'}
              >
                <PersonIcon />
              </Link>
            </Grid>
          </>)}
          {!user?.authenticated && (
            <>
              <Grid item xs={3}>
                <Link
                  component={NavLink}
                  to={'/login'}
                  sx={LinkSx}
                >
                  <PersonIcon />
                </Link>
              </Grid>
              <Grid item xs={3}>
                <Link
                  component={NavLink}
                  to={'/join'}
                  sx={LinkSx}
                >
                  <LoginIcon />
                </Link>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </HideOnScroll>
  );
};

export default MobileNav;

const LinkSx = theme => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '&.active': {
    '& > .MuiSvgIcon-root, & > .MuiBadge-root > .MuiSvgIcon-root': {
      color: theme.palette.primary.main,
    },
    '& > .MuiTypography-root': {
      color: theme.palette.primary.main,
    }
  },
  '& > .MuiSvgIcon-root, & > .MuiBadge-root > .MuiSvgIcon-root': {
    fontSize: 24,
    color: '#717171',
    height: 32,
    // [theme.breakpoints.down('xsm')]: {
    //   fontSize: 25,
    // }
    '& > path': {
      strokeWidth: 10
    }
  },
  '& > .MuiTypography-root': {
    fontSize: 14,
    lineHeight: '14px',
    fontWeight: 400,
    color: '#717171',
    textTransform: 'capitalize',
    [theme.breakpoints.down('xsm')]: {
      fontSize: 12,
    }
  }
});
