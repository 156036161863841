import { connection } from './sse';

export const notificationsMiddleware = ({getState}) => next => action => {
  const {user} = getState()
  const token = localStorage.getItem("IdToken");

  if (!connection.connection && user.authenticated && token) {
    connection.connect(token);
  }

  return next(action);
}
