import React, { useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  Typography
} from '@mui/material';
import ModalTitle from '../../../components/UI/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '../../../components/UI/Button';
import { useHistory } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Label from '../../../components/UI/Label';
import Input from '../../../components/UI/Input';
import { useLoginMutation } from '../api';
import GoogleAuthButton from '../sign-up/GoogleAuthButton';
import { isUserFillProfile } from '../../../util/isUserFillProfile';
import useFullPageHeight from '../../../util/useFullPageHeight';

const SignIn = ({open, onClose, onOpenForgotPasswordModal}) => {
  const history = useHistory();
  const [form, setForm] = useState({username: '', password: ''});
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [login, {isLoading, error}] = useLoginMutation();
  const height = useFullPageHeight();

  const handleChange = ({target: {name, value}}) => {
    setForm(state => ({...state, [name]: value}));
  };

  const handleSubmit = () => {
    if (form?.username !== "" && form?.password !== "") {
      login(form).then(res => {
        if (res?.data?.user) {
          history.push(isUserFillProfile(res?.data?.user) ? '/parties-gallery' : '/complete-your-profile');
        }
      });
      return;
    }
    if (form?.username === "") {
      setErrors((state) => ({...state, username: 'Username cannot be empty'}));
    }
    if (form?.password === "") {
      setErrors((state) => ({...state, password: 'Password cannot be empty'}));
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          maxWidth: {xs: '100%', sm: 600},
          width: '100%',
          height: {xs: height, sm: 'auto'},
          maxHeight: {xs: '100%', sm: 'auto'},
          m: {xs: 0, sm: 1}
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#b0b0b04d",
        },
      }}
    >
      <ModalTitle onClose={onClose} sx={{borderBottom: 'none'}}>
        <Typography sx={{fontSize: 15, fontWeight: 600}}>WELCOME BACK</Typography>
      </ModalTitle>
      <DialogContent sx={{px: 2, mt: 3}}>
        <Box sx={{maxWidth: 340, width: '100%', mx: 'auto'}}>
          <GoogleAuthButton sx={{mb: 3}} />
          <Divider variant="fullWidth" sx={{mb: 3}}/>
          <Label htmlFor="username">
            Username
          </Label>
          <Input
            id="username"
            name="username"
            type="text"
            placeholder="Enter Username"
            value={form?.username}
            onChange={handleChange}
            helperText={errors.username}
            error={!!(errors.username || error?.data?.error)}
            fullWidth
          />
          <Label sx={{mt: 3}} htmlFor="password">
            Password
          </Label>
          <Input
            id="password"
            name="password"
            placeholder="Enter Password"
            type={showPassword ? "text" : "password"}
            value={form?.password}
            onChange={handleChange}
            error={!!(errors.password || error?.data?.error)}
            helperText={errors.password}
            InputProps={{
              endAdornment:
                <InputAdornment
                  sx={{ backgroundColor: "transparent" }}
                  position="end"
                >
                  <IconButton
                    onClick={() => setShowPassword(state => !state)}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff sx={{ fill: "#696969" }} /> : <Visibility sx={{ fill: "#696969" }} />}
                  </IconButton>
                </InputAdornment>
            }}
            fullWidth
          />
          {error?.data?.error && (
            <Typography sx={{fontSize: "15px", color: "#F44336", mb: "20px", mt: '20px'}}>
              {error.data.error}
            </Typography>
          )}
          <FormControlLabel
            sx={{
              mt: 3,
              ml: 0,
              '& .MuiCheckbox-root': { p: 0 },
              '& .MuiFormControlLabel-label': {
                ml: 1,
                fontWeight: 'normal',
                fontSize: 15,
                fontFamily: 'Roboto, sans-serif'
              }
            }}
            control={
              <Checkbox
                sx={{
                  "&:hover": {
                    bgcolor: "transparent",
                  },
                }}
                disableRipple
                color="default"
                checkedIcon={<BpCheckedIcon />}
                icon={<BpIcon />}
                defaultChecked
              />
            }
            label="Remember me"
          />
          <Typography
            sx={{fontSize: "14px", color: "#0E1D31", textDecoration: "underline", cursor: "pointer", mt: 2.5}}
            onClick={onOpenForgotPasswordModal}
          >
            Forgot your password?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            sx={{mt: 3}}
            disabled={isLoading}
            fullWidth
            onClick={handleSubmit}
          >
            LOG IN
            {isLoading && (
              <CircularProgress size={30} />
            )}
          </Button>
          <Typography sx={{mt: 2, fontSize: "14px"}}>
            Don't have an account?
            <Typography
              component={'span'}
              sx={{fontSize: "14px", color: "#0E1D31", textDecoration: "underline", cursor: "pointer", ml: 0.5}}
              onClick={() => history.push('/join')}
            >
              Become a member
            </Typography>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SignIn;

const BpIcon = styled("span")({
  borderRadius: "4px",
  border: "1px solid #0E1D31",
  width: 22,
  height: 22,
  position: "relative",
});

const BpCheckedIcon = styled(BpIcon)({
  "&:before": {
    display: "block",
    content: '""',
    position: "absolute",
    top: "3px",
    left: "7px",
    width: "5px",
    height: "10px",
    border: "solid #373632",
    borderWidth: " 0 2px 2px 0",
    transform: "rotate(45deg)",
  },
});
