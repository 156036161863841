import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseQuery = fetchBaseQuery({
  baseUrl: 'https://api.theparty.club/v1/',
  // baseUrl: 'http://localhost:3165/v1/', // local env
  prepareHeaders: (headers) => {
    const token = localStorage.getItem("IdToken");
    if (token) {
      headers.append('authorization', `Bearer ${token}`)
    }
    return headers
  }
});
export const TAGS = {
  HOST_INVITATIONS: 'HOST_INVITATIONS',
  INVITATION: 'INVITATION',
  DRAFT: 'DRAFT',
  APPLICATIONS: 'APPLICATIONS',
  APPLICATION: 'APPLICATION',
  USER: 'USER',
  USERS: 'USERS',
  WAITLISTED_USERS: 'WAITLISTED_USERS',
  NOTIFICATIONS: 'NOTIFICATIONS',
  CONVERSATIONS: 'CONVERSATIONS',
  CONVERSATION: 'CONVERSATION',
  APPLIED_USERS: 'APPLIED_USERS',
}

export const mainApi = createApi({
  reducerPath: 'mainApi',
  baseQuery,
  tagTypes: Object.values(TAGS),
  endpoints: (build) => ({
    travelCategories: build.query({
      query: ({params}) => {
        return {url: 'packages', params};
      },
    }),
    updateProfile: build.mutation({
      query: ({id,body}) => {
        return {
          url: `users/${id}`,
          method: 'PATCH',
          body
        }
      },
      invalidatesTags: [TAGS.USER, TAGS.APPLICATION],
    }),
    updateProfileImg: build.mutation({
      query: ({id, image}) => {
        const formData = new FormData();
        formData.append('img', image);
        return {
          url: `/users/image/${id}`,
          method: 'PATCH',
          body: formData
        };
      },
    }),
    deleteProfileImg: build.mutation({
      query: ({id, body}) => {
        return {
          url: `/users/delete-image/${id}`,
          method: 'DELETE',
          body
        };
      },
    }),
    usersUsedReferrals: build.query({
      query: () => 'users/byrefcode',
    }),
    instagramProfile: build.mutation({
      query: ({body}) => {
        return {
          url: `auth/instagram-access-token`,
          method: 'POST',
          body
        }
      }
    }),
  })
});

export const {
  useTravelCategoriesQuery,
  useUpdateProfileMutation,
  useUpdateProfileImgMutation,
  useUsersUsedReferralsQuery,
  useDeleteProfileImgMutation,
  useInstagramProfileMutation,
} = mainApi;
