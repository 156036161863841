import { Box, Container, Dialog, Link, MenuItem, Stack, useMediaQuery } from '@mui/material';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Dropdown from './UI/Dropdown';
import React, { useState } from 'react';
import { LogOutModal } from '../features/profile';
import { useSelector } from 'react-redux';
import Logo from "./../assets/party-club-logo.svg";
import { Button } from './UI/Button';
import { theme } from '../services/Theme';
import SignIn from '../features/auth/sign-in';
import ForgotPasswordDialog from '../features/auth/ForgotPasswordDialog';
import HideOnScroll from './HideOnScroll';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationDropdown from './NotificationDropdown';

const AppHeader = () => {
  const {user} = useSelector(store => store);
  const [openLogOutModal, setOpenLogOutModal] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const hideAuthButtons = location.pathname === '/' || location.pathname === '/join' || location.pathname === '/login';

  const handleOpenRegister = () => {
    history.push('/join');
  };

  const handleOpenForgotPassword = () => {
    setOpenForgotPassword(true);
    setOpenLogin(false);
  };

  return (
    <HideOnScroll>
      <Box
        sx={{
          display: {xs: hideAuthButtons ? 'block' : 'none', sm: 'block'},
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: 100,
          boxShadow: 'rgb(0 0 0 / 8%) 0 1px 0;',
          backgroundColor: '#fff'
        }}
      >
        <Container>
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} sx={{height: 65}} spacing={2}>
            <Link
              to={'/'}
              component={NavLink}
              sx={{
                maxWidth: 130,
                width: '100%',
                '& > img': {
                  width: '100%',
                  height: 'auto'
                }
              }}
            >
              <img src={Logo} alt="invite-club-logo"/>
            </Link>
            {user?.authenticated && (
              <Stack direction={'row'} alignItems={'center'} spacing={{sm: 2, md: 4}}>
                <Link
                  to={'/parties-gallery'}
                  sx={LinkSx}
                  underline={'none'}
                  component={NavLink}
                >
                  party gallery
                </Link>
                {user?.user?.role !== 'admin' && (
                  <Link
                    to={'/my-parties'}
                    sx={LinkSx}
                    underline={'none'}
                    component={NavLink}
                  >
                    my parties
                  </Link>
                )}
                {user?.user?.role === 'admin' && (
                  <Link
                    to={'/user-management'}
                    sx={LinkSx}
                    underline={'none'}
                    component={NavLink}
                  >
                    user management
                  </Link>
                )}
                <Link
                  to={'/inbox'}
                  sx={LinkSx}
                  underline={'none'}
                  component={NavLink}
                >
                  inbox
                </Link>
              </Stack>
            )}
            {user?.authenticated && (<>
              <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <NotificationDropdown />
                <Dropdown
                  sx={{display: 'flex', alignItems: 'center'}}
                  dropdownItems={
                    <Box>
                      <MenuItem
                        sx={{fontSize: 16, fontWeight: 400, minWidth: 200, py: 1.5, px: 2.5}}
                        component={NavLink}
                        to={"/profile"}
                      >
                        Profile
                      </MenuItem>
                      <MenuItem
                        sx={{fontSize: 16, fontWeight: 400, py: 1.5, px: 2.5}}
                        to={"/profile"}
                        onClick={() => setOpenLogOutModal(true)}
                      >
                        Logout
                      </MenuItem>
                    </Box>
                  }
                >
                  <Stack
                    direction={'row'}
                    alignItems={'center'}
                    spacing={1}
                    sx={{
                      borderRadius: 30,
                      border: '1px solid rgb(221, 221, 221)',
                      height: 48,
                      py: 1,
                      pr: 1,
                      pl: 1.5,
                      transition: 'box-shadow 200ms cubic-bezier(0.2,0,0,1)',
                      '&:hover': {
                        boxShadow: '0 2px 4px rgba(0,0,0,0.18)'
                      }
                    }}
                  >
                    <MenuIcon sx={{fontSize: 18}} />
                    <Box sx={{borderRadius: '50%', overflow: 'hidden', width: 32, height: 32, '& > img, & > svg': {width: '100%', height: '100%', objectFit: 'cover'}}}>
                      {user?.user?.imgUrl?.length > 0 ? (
                        <img src={user?.user?.imgUrl[user?.user?.imgUrl.length - 1].src} alt="user-profile"/>
                      ) :  <AccountCircle/>}
                    </Box>
                  </Stack>
                </Dropdown>
              </Stack>
            </>)}
            {!user?.authenticated && !hideAuthButtons && (
              <Box>
                <Button variant={'contained'} color={'primary'} sx={{height: 32}} onClick={handleOpenRegister}>
                  Sign up
                </Button>
                <Button variant={'outlined'} color={'primary'} sx={{height: 32, ml: 2}} onClick={() => setOpenLogin(true)}>
                  LOGIN
                </Button>
              </Box>
            )}
          </Stack>
        </Container>
        <Dialog
          fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
          sx={{
            '& .MuiPaper-root': {
              maxWidth: {xs: '100%', sm: 600},
              width: '100%',
            },
            "& .MuiBackdrop-root": {
              backgroundColor: "#b0b0b04d",
            },
          }}
          open={openLogin}
          onClose={() => setOpenLogin(false)}
        >
          <SignIn open={openLogin} onClose={() => setOpenLogin(false)} onOpenForgotPasswordModal={handleOpenForgotPassword}/>
        </Dialog>
        <Dialog
          sx={{
            '& .MuiPaper-root': {
              maxWidth: 600,
              width: '100%',
            },
            "& .MuiBackdrop-root": {
              backgroundColor: "#b0b0b04d",
            },
          }}
          open={openForgotPassword}
          onClose={() => setOpenForgotPassword(false)}
        >
          <ForgotPasswordDialog open={openForgotPassword} onClose={() => setOpenForgotPassword(false)}/>
        </Dialog>
        <LogOutModal open={openLogOutModal} onClose={() => setOpenLogOutModal(false)} />
      </Box>
    </HideOnScroll>
  );
};

export default AppHeader;

const LinkSx = {
  fontSize: {sm: 12, md: 16},
  textTransform: 'capitalize',
  fontWeight: 400,
  color: '#000',
  opacity: 0.5,
  transition: 'opacity 200ms linear 200ms',
  '&.active': {
    opacity: 1
  },
  '&:hover:not(.active)': {
    opacity: 0.8
  }
};
