import React from 'react';
import { Container } from '@mui/material';
import Footer from './Footer';
import AppHeader from './AppHeader';
import MobileNav from './MobileNav';
import useFullPageHeight from '../util/useFullPageHeight';

const PageWrapper = ({hideMobileNav = false, children, sx}) => {
  const height = useFullPageHeight();

  return (
    <>
      <AppHeader />
      <Container sx={{
        height: '100%',
        minHeight: height,
        pt: {xs: '20px', sm: '70px', md: '95px'},
        pb: {xs: '0', md: '65px'},
        display: 'flex',
        flexDirection: 'column',
        ...sx
      }}>
        {children}
      </Container>
      <Footer />
      {!hideMobileNav && <MobileNav />}
    </>
  )
};

export default PageWrapper;
