import { configureStore } from '@reduxjs/toolkit';
import { mainApi } from '../services/mainApi';
import newInvitation from '../features/invitations/slice';
import application from '../features/applications/slice'
import inbox from '../features/inbox/slice'
import notifications from '../features/notifications/slice'
import user from '../features/auth/slice'
import { errorMiddleware } from '../services/errors.middleware';
import { notificationsMiddleware } from '../services/notifications.middleware';

const middlewares = [mainApi.middleware, errorMiddleware, notificationsMiddleware];

const apiReducers = {
  [mainApi.reducerPath]: mainApi.reducer
}

const store = configureStore({
  reducer: {
    ...apiReducers,
    user,
    newInvitation,
    application,
    inbox,
    notifications,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares)
})

export default store;
