import StaticPageWrapper from '../../../components/StaticPageWrapper';
import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { Container, Divider, IconButton, InputAdornment, Typography } from '@mui/material';
import Label from '../../../components/UI/Label';
import Input from '../../../components/UI/Input';
import InstagramIcon from '@mui/icons-material/Instagram';
import dayjs from 'dayjs';
import { Button } from '../../../components/UI/Button';
import { useCheckValueMutation, useLoginMutation, useRegisterMutation } from '../api';
import SuccessApplyDialog from './SuccessApplyDialog';
import GoogleAutocomplete from '../../../components/GoogleAutocomplete';
import CustomDateField from '../../../components/CustomDateField';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import GoogleAuthButton from './GoogleAuthButton';
import { isUserFillProfile } from '../../../util/isUserFillProfile';

const SignUp = () => {
  const [form, setForm] = useState({role: 'user'});
  const [errors, setErrors] = useState({});
  const isDisabled = !form?.username || !form?.password || !form?.confirmPassword || !form?.email || !form?.instagram ||
    !form?.birthDate || !form?.liveIn;
  const [checkValue, {isLoading: isCheckLoading}] = useCheckValueMutation();
  const [signUp, {isLoading, error}] = useRegisterMutation();
  const [login] = useLoginMutation();
  const [open, setOpen] = useState(false);
  const [passwordFields, setPasswordFields] = useState({password: false, confirmPassword: false});
  const history = useHistory();

  const handleChange = ({target: {value, name}}) => {
    setForm(state => ({...state, [name]: value}));
    if (value === "") {
      setErrors((state) => ({...state, [name]: `${name} cannot be empty`,}));
      return;
    }
    if (name === 'email' && !String(value).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      setErrors(state => ({...state, [name]: 'Enter a valid email address'}))
      return;
    }
    if (name === 'password') {
      if (value.length < 8) {
        setErrors(state => ({...state, password: "Too short"}));
        return;
      }
      if (value.length > 25) {
        setErrors(state => ({...state, password: "Too long"}));
        return;
      }
    }
    if (name === 'confirmPassword') {
      if (form?.password !== value) {
        setErrors(state => ({...state, confirmPassword: "Passwords don't match"}));
        return;
      }
    }
    setErrors(state => ({...state, [name]: null}))
  };

  const handleDateChange = (e) => (name) => {
    setForm(state => ({...state, [name]: dayjs(e?.$d || null).format()}));
  };

  const handleLocation = (key) => (value) => {
    setForm(state => ({...state, [key]: value}));
  };

  const toggleField = (name) => () => {
    setPasswordFields(state => ({...state, [name]: !state[name]}));
  };

  const handleSignUp = () => {
    checkValue({key: 'email', body: {email: form?.email}}).then(res => {
      if (res?.data?.result) {
        setErrors(state => ({...state, email: 'Email already exist'}));
        window.scrollTo(0, 0);
      } else {
        checkValue({key: 'username', body:{username: form?.username}}).then(res => {
          if (res?.data?.result) {
            setErrors(state => ({...state, username: 'Username already exist'}));
            window.scrollTo(0, 0);
          } else {
            signUp(form).then(res => {
              if (res?.data?.success) {
                login({username: form?.username, password: form?.password}).then(res => {
                  if (res?.data?.user) {
                    history.push(isUserFillProfile(res?.data?.user) ? '/parties-gallery' : '/complete-your-profile');
                  }
                });
              }
            });
          }
        })
      }
    });
  };

  return (
    <StaticPageWrapper>
      <Container sx={{maxWidth: {xs: 420}, pt: 3}}>
        <Typography sx={{textTransform: 'uppercase', fontSize: 16, fontWeight: 500, mb: 1}}>
          sign up
        </Typography>
        <Typography sx={{fontSize: 14, fontWeight: 400, lineHeight: '18px', mb: 2}}>
          Submit the below information to sign up
        </Typography>
        <GoogleAuthButton sx={{mb: 3}} />
        <Divider variant="fullWidth" sx={{mb: 3}}/>
        <Label sx={{mb: 0.5}}>Username</Label>
        <Input
          name={'username'}
          value={form?.username || ''}
          onChange={handleChange}
          error={Boolean(errors?.username)}
          helperText={errors?.username}
          fullWidth
          sx={{mb: 2}}
        />
        <Label htmlFor="password">
          Password
        </Label>
        <Input
          name="password"
          type={passwordFields.password ? "text" : "password"}
          placeholder="Enter Password"
          value={form.password || ''}
          onChange={handleChange}
          helperText={errors?.password}
          error={!!errors?.password}
          fullWidth
          sx={{mb: 2}}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
              >
                <IconButton
                  onClick={toggleField('password')}
                  onMouseDown={e => e.preventDefault()}
                  edge="end"
                >
                  {passwordFields.password ? (
                    <VisibilityOff sx={{color: "#6B6963"}} />
                  ) : (
                    <Visibility sx={{color: "#6B6963"}} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            autoComplete: 'new-password',
            form: {
              autoComplete: 'off',
            },
          }}
        />
        <Label htmlFor="password">
          Confirm Password
        </Label>
        <Input
          name="confirmPassword"
          type={passwordFields.confirmPassword ? "text" : "password"}
          placeholder="Re Enter Password"
          value={form.confirmPassword || ''}
          onChange={handleChange}
          helperText={errors?.confirmPassword}
          error={!!errors?.confirmPassword}
          fullWidth
          sx={{mb: 2}}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
              >
                <IconButton
                  onClick={toggleField('confirmPassword')}
                  onMouseDown={e => e.preventDefault()}
                  edge="end"
                >
                  {passwordFields.confirmPassword ? (
                    <VisibilityOff style={{ color: "#6B6963" }} />
                  ) : (
                    <Visibility style={{ color: "#6B6963" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
            autoComplete: 'new-password',
            form: {
              autoComplete: 'off',
            },
          }}
        />
        <Label sx={{mb: 0.5}}>Email</Label>
        <Input
          name={'email'}
          value={form?.email || ''}
          onChange={handleChange}
          error={Boolean(errors?.email)}
          helperText={errors?.email}
          fullWidth
          sx={{mb: 2}}
        />
        <Label sx={{mb: 0.5}}>Date of birth</Label>
        <CustomDateField
          sx={{mb: 2}}
          value={form?.birthDate || null}
          onChange={(e) => handleDateChange(e)('birthDate')}
          disableFuture
        />
        <Label sx={{mb: 0.5}}>Instagram</Label>
        <Input
          name={'instagram'}
          value={form?.instagram || ''}
          onChange={handleChange}
          error={Boolean(errors?.instagram)}
          helperText={errors?.instagram}
          fullWidth
          sx={{mb: 2}}
          InputProps={{
            startAdornment: <InputAdornment position="start"><InstagramIcon color={'primary'} /></InputAdornment>,
          }}
        />
        <Label sx={{mb: 0.5}}>I live in</Label>
        <GoogleAutocomplete
          sx={{mb: 2}}
          value={form?.liveIn || null}
          handleChange={handleLocation('liveIn')}
          error={errors?.liveIn}
        />
        <Typography sx={{fontSize: 14, mb: 2, '& > a': {fontSize: 14, color: '#0E1D31'}}}>
          By clicking Apply Invite.Club, you agree to our <Link to="/terms-and-conditions">Terms & Conditions </Link> and <Link to="/privacy-policy">Privacy Policy</Link>
        </Typography>
        {error && (
          <Typography sx={{color: 'red', mb: 2, fontSize: 14}}>{error?.data?.error}</Typography>
        )}
        <Button
          variant={'contained'}
          color={'action'}
          fullWidth
          sx={{mb: 3}}
          disabled={isDisabled || isCheckLoading || isLoading}
          onClick={handleSignUp}
        >
          Sign Up
        </Button>
        <Typography sx={{fontSize: 14, mb: 2, '& > a': {fontSize: 14, color: '#0E1D31'}}}>
          Already have an account? <Link to="/login">Log in</Link>
        </Typography>
        <SuccessApplyDialog
          open={open}
          onClose={() => setOpen(false)}
          firstName={form?.firstName}
          lastName={form?.lastName}
        />
      </Container>
    </StaticPageWrapper>
  );
};

export default SignUp;
