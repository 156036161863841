import React, { useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { Button } from '../../../components/UI/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PageWrapper from '../../../components/PageWrapper';
import InvitationCard from '../../../components/InvitationCard';
import { useInvitationsQuery } from '../api';
import NoItemsBox from '../../../components/NoItemsBox';
import InvitationsFilters from '../InvitationsFilters';

const InvitationsGallery = () => {
  const [params, setParams] = useState({});
  const {data, isLoading} = useInvitationsQuery({params});
  const handleFiltersClick = (name, value) => {
    if (!value.length) {
      const {[name]: unUsed, ...newParams} = params;
      setParams(newParams);
    } else {
      setParams(params => ({...params, [name]: value}));
    }
  };

  const handleClearFilters = () => {
    setParams({});
  };

  return (
    <PageWrapper>
      <Stack direction={{xs: 'column', sm: 'row'}} alignItems={'flex-start'} justifyContent={'space-between'} sx={{mb: 2, width: '100%'}} flexWrap={'wrap'}>
        <Typography sx={{fontSize: 18, fontWeight: 400, mb:{xs: 2, sm: 0}}}>Party gallery</Typography>
        <InvitationsFilters
          params={params}
          handleClear={handleClearFilters}
          handleFilter={handleFiltersClick}
          sx={{width: {xs: '100%', sm: 'auto'}}}
        >
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/create-invitation"
            startIcon={<AddCircleIcon />}
            sx={{height: 'auto', py: 1, color: '#000'}}
          >
            PLAN A NEW PARTY
          </Button>
        </InvitationsFilters>
      </Stack>
      {data?.data?.length > 0 && !isLoading && (
        <>
          <Grid container spacing={3} >
            {data?.data?.map((invitation) => (
              <Grid item xs={12} sm={6} md={3} xl={3} key={invitation.id}>
                <InvitationCard invitation={invitation} showIsApplied />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {!data?.data?.length && !isLoading && (
        <NoItemsBox
          title={params.locationFrom || params.startDate ? 'No parties found ...yet' : 'The gallery is empty at the moment.'}
          description={params.locationFrom || params.startDate ? 'Clearing filters or modifying their values can often unlock new possibilities and yield the results you\'re looking for. ' : 'Looks like the perfect moment to plan your next big event!'}
          buttonText={'Plan a New Party'}
          hideButton={params.locationFrom || params.startDate}
        />
      )}
      {isLoading && (<Typography sx={{fontSize: 16, mt: 3}}>...Loading</Typography>)}
    </PageWrapper>
  );
};

export default InvitationsGallery;
