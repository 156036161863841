import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import MediaSlider from '../../../components/MediaSlider';

const MobileSlider = ({images, video}) => {
  const [swiper, setSwiper] = useState(0);
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        width: 'calc(100% + 32px)',
        height: '350px',
        mx: -2,
        position: 'relative',
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        },
        '& .swiper': {
          height: '100%',
          width: '100%',
        },
        '& .swiper-slide':{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: {xs: 0, sm: '60px'},
          '& video': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          },
        },
        '& .swiper-button-next, & .swiper-button-prev': {
          display: {xs: 'none', sm: 'flex'},
          color: '#fff',
          borderRadius: '50%',
          border: '2px solid #fff',
          width: 50,
          height: 50,
          '&:after': {
            fontSize: 16
          }
        },
        '& .swiper-button-next': {
          right: 0,
        },
        '& .swiper-button-prev': {
          left: 0,
        },
      }}
    >
      <Swiper
        navigation={true}
        modules={[Navigation, Pagination]}
        onSlideChange={(s) => setSwiper(s.activeIndex)}
      >
        {video && (
          <SwiperSlide >
            <video onClick={() => setOpen(0)}>
              <source src={video} type="video/mp4" />
            </video>
          </SwiperSlide>
        )}
        {images?.map((image, i) => (
          <SwiperSlide key={`slide-${i}`} onClick={() => setOpen(video ? i + 1 : i)}>
            <img src={image} alt={image} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        sx={{
          position: 'absolute',
          right: 10,
          bottom: 10,
          background: '#222222a8',
          borderRadius: '4px',
          py: 0.5,
          px: 1.5,
          fontSize: 14,
          color: '#fff',
          zIndex: 100
        }}
      >
        {swiper + 1}/{video ? images?.length + 1 : images?.length}
      </Box>
      <MediaSlider open={open === +open} currentSlide={open} onClose={() => setOpen(false)} images={images} video={video}/>
    </Box>
  );
};

export default MobileSlider;
