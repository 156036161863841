import { fetchEventSource } from '@microsoft/fetch-event-source';
import store from '../redux/store';
import { addNotification, setNotifications, setNotificationsCount } from '../features/notifications/slice';
import { mainApi, TAGS } from './mainApi';

const countUnreadNotifications = (notifications) => {
  return notifications.reduce((prev, acc) => (
    !acc.read && acc.title !== 'Application cancelled' ? prev + 1 : prev + 0
  ), 0)
}

class Connection {
  constructor() {
    this.connection = null;
    this.controller = new AbortController();
  }
//local url http://localhost:3165/v1/
  connect(token) {
    this.connection = fetchEventSource(`https://api.theparty.club/v1/notifications/sse`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      signal: this.controller.signal,
      openWhenHidden: true,
      onopen(res) {
        if (res.ok && res.status === 200) {
          console.log("Connection made ", res);
        } else if (
          res.status >= 400 &&
          res.status < 500 &&
          res.status !== 429
        ) {
          console.log("Client side error ", res);
        }
      },
      onmessage(event) {
        const parsedData = JSON.parse(event.data);
        if (parsedData.event === 'new notification') {
          store.dispatch(addNotification(parsedData.notification));
          if (parsedData.notification.title === 'Application status changed') {
            store.dispatch(mainApi.util.invalidateTags([TAGS.APPLICATIONS]));
          }
          if (parsedData.notification.title === 'New Application') {
            store.dispatch(mainApi.util.invalidateTags([TAGS.APPLICATIONS, TAGS.HOST_INVITATIONS, TAGS.INVITATION]));
          }
          if (parsedData.notification.title === 'New Party Chat is Now Active!') {
            store.dispatch(mainApi.util.invalidateTags([TAGS.CONVERSATIONS]));
          }
        }
        if (parsedData.event === 'allNotifications') {
          store.dispatch(setNotifications(parsedData.notifications));
          store.dispatch(setNotificationsCount(countUnreadNotifications(parsedData.notifications)));
        }
      },
      onclose() {
        console.log("Connection closed by the server");
      },
      onerror(err) {
        console.log("There was an error from server", err);
      },
    })
  }

  disconnect() {
    if (this.connection) {
      this.controller.abort();
      this.connection = null;
    }
  }
}

export const connection = new Connection();
