import React, { useEffect } from 'react';
import { Route, Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyGetUserQuery } from '../../features/auth/api';
import { logout } from '../../features/auth/slice';
import jwtDecode from "jwt-decode";
import dayjs from 'dayjs';
import { isEmptyObject } from '../../util/utils';

const PrivateRoute = ({ component: Component, ...props }) => {
  const token = localStorage.getItem("IdToken");
  const tokenExp = token ? jwtDecode(token)?.exp : false;
  const dispatch = useDispatch();
  const history = useHistory();
  const {user} = useSelector(store => store.user);
  const { pathname } = useLocation();

  const [getUser] = useLazyGetUserQuery();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!token || tokenExp < dayjs().unix()) {
      dispatch(logout());
    }
    if (isEmptyObject(user) && token){
      getUser();
    }
  }, [dispatch, getUser, tokenExp, history, token, user]);

  return (
    <Route
      {...props}
      render={(props) =>
        !token ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  )
};

export default PrivateRoute;
