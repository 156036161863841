import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthRoute = ({ component: Component, ...props }) => {
  const token = localStorage.getItem("IdToken");
  return (
    <Route
      {...props}
      render={(props) =>
        token ? <Redirect to="/parties-gallery" /> : <Component {...props} />
      }
    />
  )
};

export default AuthRoute;
