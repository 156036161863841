import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation, Pagination } from 'swiper';
import React, { useState, useEffect} from 'react';

const MediaSlider = ({open, onClose, currentSlide = 0, isUser, images, video}) => {
  const [swiper, setSwiper] = useState(0);

  useEffect(() => {
    if (typeof currentSlide === 'number') {
      setSwiper(currentSlide);
    }
  }, [currentSlide])

  return (
    <Dialog
      open={open}
      fullScreen
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': {
          background: 'transparent',
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#000",
        },
      }}
    >
      <Stack
        alignItems={'center'}
        justifyContent={'center'} sx={{width: '100%', height: '100%', px: {xs: 0, sm: 2}, py: {xs: 4, sm: 6}, position: 'relative'}}>
        <Stack direction={'row'} alignItems={'center'} justifyContent={'flex-end'} sx={{position: 'absolute', left: 0, top: 0, width: '100%'}}>
          <Typography sx={{color: '#fff', fontSize: 18, mx: 'auto'}} aligh={'center'}>{swiper + 1}/{video ? images?.length + 1 : images?.length}</Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              '& > svg': {
                fill: '#fff',
                fontSize: 30,
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            '& img': {
              maxWidth: '100%',
              maxHeight: '100%',
            },
            '& .swiper': {
              height: '100%',
              width: '100%',
            },
            '& .swiper-slide':{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: {xs: 0, sm: '60px'},
              '& video': {
                width: '100%',
                height: '100%',
              },
            },
            '& .swiper-button-next, & .swiper-button-prev': {
              display: {xs: 'none', sm: 'flex'},
              color: '#fff',
              borderRadius: '50%',
              border: '2px solid #fff',
              width: 50,
              height: 50,
              '&:after': {
                fontSize: 16
              }
            },
            '& .swiper-button-next': {
              right: 0,
            },
            '& .swiper-button-prev': {
              left: 0,
            },
          }}
        >
          <Swiper
            spaceBetween={10}
            navigation={true}
            modules={[Navigation, Pagination, Keyboard]}
            initialSlide={currentSlide || 0}
            onSlideChange={(s) => setSwiper(s.activeIndex)}
            keyboard={true}
          >
            {video && (
              <SwiperSlide>
                <video controls style={{width: '100%', height: '100%'}}>
                  <source src={video} type="video/mp4" />
                </video>
              </SwiperSlide>
            )}
            {images?.map((image, i) => (
              <SwiperSlide key={`slide-${i}`}>
                <img src={isUser ? image.src : image} alt={image} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Stack>
    </Dialog>
  );
};

export default MediaSlider;
