import { createSlice } from '@reduxjs/toolkit';
import { AuthApi } from './api';
import { mainApi } from '../../services/mainApi';

const initialState = {
  authenticated: false,
  user: {},
  referrals: {},
  notifications: [],
  forgotPasswordEmailSent: false,
  resetPassword: false,
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => {
      localStorage.clear();
      window.location.href = "/login";
      return initialState;
    },
    addProfileImage: (state, {payload}) => {
      state.user.imgUrl = [...state.user.imgUrl, payload];
    },
    deleteProfileImage: (state, {payload}) => {
      state.user.imgUrl = state.user.imgUrl.filter(item => item.name !== payload);
    }
  },
  extraReducers: builder => {
    builder.addMatcher(
      AuthApi.endpoints.login.matchFulfilled,
      handleAuth,
    ).addMatcher(
      AuthApi.endpoints.authByGoogle.matchFulfilled,
      handleAuth,
    ).addMatcher(
      AuthApi.endpoints.getUser.matchFulfilled,
      (state, {payload}) => {
        state.authenticated = true;
        state.user = {...payload.user, imgUrl: payload?.user?.imgUrl?.map(item => ({src: item, name: item})) || []};
      }
    ).addMatcher(
      mainApi.endpoints.updateProfile.matchFulfilled,
      (state, {payload}) => {
        state.user = payload.user;
      }
    ).addMatcher(
      mainApi.endpoints.createApplication.matchFulfilled,
      (state, {payload}) => {
        if (payload.shortBio) {
          state.user.shortBio = payload.shortBio;
        }
        if (payload.interests) {
          state.user.interests = payload.interests;
        }
      }
    )
  }
});

const handleAuth = (state, {payload}) => {
  if (payload?.jwtToken) {
    state.authenticated = true;
    localStorage.setItem("IdToken", payload?.jwtToken);
  }
  state.user = {...payload.user, imgUrl: payload?.user?.imgUrl?.map(item => ({src: item, name: item})) || []};
};

export const {
  logout,
  addProfileImage,
  deleteProfileImage
} = user.actions;
export default user.reducer;
