import jwtDecode from 'jwt-decode';
import { Route } from 'react-router-dom';
import { useLazyGetUserQuery } from '../../features/auth/api';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { isEmptyObject } from '../../util/utils';

const PublicRoute = ({component: Component, ...props}) => {
  const token = localStorage.getItem("IdToken");
  const tokenExp = token ? jwtDecode(token)?.exp : false;
  const {user} = useSelector(store => store.user);

  const [getUser] = useLazyGetUserQuery();

  useEffect(() => {
    if (isEmptyObject(user) && (token || tokenExp > dayjs().unix())) {
      getUser();
    }
  }, [user, tokenExp, token, getUser]);

  return <Route{...props} render={(props) => <Component {...props} />}/>
};

export default PublicRoute;
