import React, { useMemo } from 'react';
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import { Button } from './UI/Button';
import { useSelector } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';

const InvitationCard = ({
  invitation: {
    id,
    name,
    startDate,
    startTime,
    endTime,
    locationFrom,
    status,
    totalApplications,
    eventPhotos,
  }, showStatus = false,
     showApplications = false,
     showIsApplied = false,
}) => {
  const history = useHistory();
  const {user} = useSelector((store) => store.user);
  const isApplied = useMemo(() => (Boolean(totalApplications?.find(application => application.user === user.id))), [user.id, totalApplications])
  const goToApplications = (e) => {
    e.stopPropagation();
    history.push(`/applications/${id}`);
  };

  return (
    <Card
      sx={{
        width: "100%",
        position: "relative",
        boxShadow: "unset",
        overflow: 'visible',
        '& > a': {
          textDecoration: "unset",
        },
      }}
      onClick={() => history.push(`/invitation/${id}`)}
    >
      <Box sx={{
        width: "100%",
        height: 280,
        position: "relative",
        boxShadow: "unset",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        cursor: "pointer",
        backgroundColor: "#ccc",
        backgroundPosition: "center",
        borderRadius: '20px',
        overflow: 'hidden',
        '& > img': {
          width: "100%",
          height: '100%',
          objectFit: 'cover'
        },
      }}>
        {eventPhotos?.length > 0 && <img src={eventPhotos[0]} alt={name}/>}
        {showApplications && (
          <Button
            variant={'contained'}
            color={'secondary'}
            sx={{
              textTransform: 'uppercase',
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              width: 'calc(100% - 16px)',
              height: 'auto',
              py: 1,
              fontWeight: 500
            }}
            onClick={goToApplications}
            disabled={totalApplications?.length === 0}
          >
            {totalApplications?.length} {totalApplications?.length === 1 ? 'Application' : 'Applications'}
          </Button>
        )}
        {showIsApplied && isApplied && (
          <Stack
            direction={'row'}
            alignItems={'center'}
            sx={{
              position: 'absolute',
              right: 10,
              top: 20,
              background: '#248c4d',
              px: 3,
              py: 1.25,
              borderRadius: '16px',
              color: '#fff',
              boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
              backdropFilter: 'blur(20px)',
              border: '1px solid #007771',
            }}
          >
            <CheckIcon sx={{color: '#fff', fontSize: 20, mr: 0.5}} />Applied
          </Stack>
        )}
      </Box>
      <CardContent sx={{
        padding: "12px 0 0 0",
        height: "100%",
        '&:last-child': {
          pb: 0,
        }
      }}>
        <Typography sx={{
          color: "#000",
          fontSize: "15px",
          fontWeight: "600",
        }}>
          {name}
        </Typography>
        {(locationFrom?.city || locationFrom?.country) && (
          <Typography sx={{
            color: "#000",
            fontSize: "15px",
            fontWeight: "100",
          }}>
            {locationFrom?.city}, {locationFrom?.country}
          </Typography>
        )}
        <Typography sx={{
          color: "#000",
          fontSize: "14px",
          fontWeight: "100",
        }}>
          {dayjs(startDate).format("DD MMM YYYY")} · {dayjs(startTime).format("HH:mm")}  {dayjs(endTime).isValid() ? ` - ${dayjs(endTime).format("HH:mm")}` : ''}
        </Typography>
        {showStatus && (
          <Typography
            sx={{
              textTransform: "uppercase",
              fontSize: "14px",
              fontWeight: "100",
              color: "#000",
              display: 'flex',
              alignItems:'center'
            }}>
            {status === 'draft' && <DescriptionIcon sx={{ mr: '2px', fontSize: 15}} />}
            {status}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default InvitationCard;
