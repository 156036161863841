import { Grid, Typography, IconButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../components/UI/Label';
import React, { memo, useState } from 'react';
import { useDeleteProfileImgMutation, useUpdateProfileImgMutation } from '../../services/mainApi';
import CloseIcon from '@mui/icons-material/Close';
import Loader from '../../components/Loader';
import { useParams } from 'react-router-dom';
import MediaSlider from '../../components/MediaSlider';
import { blobToDataURL } from '../../util/utils';
import { addProfileImage, deleteProfileImage } from '../auth/slice';

const ProfilePhotoTiles = memo(({showActions = false, sx, images = [], isUser = false}) => {
  const {user} = useSelector(store => store.user);
  const [updateImage, {isLoading}] = useUpdateProfileImgMutation();
  const [deleteImage, {isLoading: isDeleteLoading}] = useDeleteProfileImgMutation();
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const params = useParams();
  const profileImages = isUser ? user?.imgUrl : images;
  const dispatch = useDispatch();

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    if (!e?.target?.files[0]) {
      return;
    }
    if (e?.target?.files[0].size > 4000000) {
      setError('Image size should be less than 4mb.');
      return;
    }
    setError(null);
    updateImage({id: user?.id, image: e.target.files[0]}).then(res => {
      if (res?.data?.newImage) {
        blobToDataURL(file, (dataUrl) => {
          dispatch(addProfileImage({src: dataUrl, name: res?.data?.newImage}));
        })
      }
    });
    e.target.value = '';
  };

  const handleDelete = (e) => (imageLink) => {
    e.stopPropagation();
    deleteImage({id: user.id, body: {imageLink}}).then(res => {
      if (res.data) {
        dispatch(deleteProfileImage(imageLink));
      }
    });
  };

  return (
    <>
      {params?.id === user?.id && <Typography sx={{mb: 2, width: '100%'}} align={'left'}>My Photos</Typography>}
      <Grid container spacing={1} sx={{transform: 'translate3d(0, 0, 0)', justifyContent: 'flex-start', ...sx}}>
        {profileImages?.map((img, i) => (
          <Grid
            onClick={() => setOpen(i)}
            key={i}
            item
            xs={6}
            sm={4}
            sx={{
              aspectRatio: '1',
              position: 'relative',
              '& > img': {
                width: '100%',
                height: '100%',
                display: 'block',
                borderRadius: '20px',
                overflow: 'hidden',
                objectFit: 'cover',
                WebkitBackfaceVisibility: "hidden",
                msTransform: "translateZ(0)",
                WebkitTransform: "translateZ(0)",
                transform: "translateZ(0)",
                cursor: 'pointer',
                transition: 'all 300ms linear 200ms',
              },
            }}
          >
            <img src={isUser ? img.src : img} alt={img} />
            {showActions && (
              <IconButton
                onClick={(e) => handleDelete(e)(img.name)}
                disabled={isLoading}
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                  borderRadius: '50%',
                  background: '#fff',
                  padding: 0.5,
                  boxShadow: '0 2px 4px rgba(50, 55, 64, 1)',
                  '&:hover': {
                    background: '#fff',
                  },
                  '& > svg': {
                    fill: '#000',
                    fontSize: 18,
                  }
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </Grid>
        ))}
        {profileImages?.length < 6 && showActions && (
          <Grid item xs={6} sm={4} sx={{aspectRatio: '1'}}>
            <Label
              htmlFor={'profileImage'}
              sx={{
                '& > input': {display: 'none'},
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                cursor: 'pointer',
                background: '#e6e6e6',
                borderRadius: '20px',
                overflow: 'hidden',
                '&:hover': {
                  '.MuiTypography-root': {
                    transform: 'scale(1.5)'
                  }
                }
              }}
            >
              <input type="file" accept="image/*" id={'profileImage'} onChange={handleUploadImage}/>
              <Typography sx={{fontSize: 40, transition: 'all 300ms linear 200ms'}}>+</Typography>
            </Label>
          </Grid>
        )}
        {/*{profileImages?.length < 6 && !showActions && (Array(6 - profileImages?.length).fill(null).map((_, i) => (*/}
        {/*  <Grid item xs={6} sm={4} sx={{aspectRatio: '1'}} key={i}>*/}
        {/*    <Label*/}
        {/*      htmlFor={'profileImage'}*/}
        {/*      sx={{*/}
        {/*        '& > input': {display: 'none'},*/}
        {/*        display: 'flex',*/}
        {/*        alignItems: 'center',*/}
        {/*        justifyContent: 'center',*/}
        {/*        width: '100%',*/}
        {/*        height: '100%',*/}
        {/*        background: '#e6e6e6',*/}
        {/*        borderRadius: '20px',*/}
        {/*        overflow: 'hidden',*/}
        {/*        '&:hover': {*/}
        {/*          '.MuiTypography-root': {*/}
        {/*            transform: 'scale(1.5)'*/}
        {/*          }*/}
        {/*        }*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Grid>*/}
        {/*)))}*/}
      </Grid>
      {Boolean(error) && <Typography align={'center'} sx={{fontSize: 14, mb: 4, color: 'red'}}>{error}</Typography>}
      <MediaSlider open={open === +open} currentSlide={open} onClose={() => setOpen(false)} images={profileImages} isUser={isUser} />
      {(isLoading || isDeleteLoading) && <Loader />}
    </>
  );
});

export default ProfilePhotoTiles;
