import { createTheme as createThemeV4 } from '@material-ui/core/styles';
import { createTheme as createThemeV5 } from '@mui/material/styles';

export const themeV4 = createThemeV4({
  palette: {
    primary: {
      main: "#0E1D31",
      default: "#0E1D31",
    },
    secondary: {
      main: "#000000",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      xsm: 320,
      sm: 640,
      md: 1030,
      lg: 1360,
      xl: 1536,
    },
  },
});
export const theme = createThemeV5({
  palette: {
    primary: {
      main: "#0E1D31",
      default: "#0E1D31",
    },
    secondary: {
      main: "#000000",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      xsm: 320,
      sm: 640,
      md: 1030,
      lg: 1360,
      xl: 1536,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthMd: {
          maxWidth: 1180,
        },
        maxWidthLg: {
          maxWidth: 1280,
        },
      },
    },
  },
});
