import StaticPageWrapper from '../components/StaticPageWrapper';
import { Box, Container, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Button } from '../components/UI/Button';
import SignIn from '../features/auth/sign-in';
import { useSelector } from 'react-redux';
import ForgotPasswordDialog from '../features/auth/ForgotPasswordDialog';
import ResetPasswordDialog from '../features/auth/ResetPasswordDialog';
import { useHistory, useLocation } from 'react-router-dom';
import useFullPageHeight from '../util/useFullPageHeight';

const Home = () => {
  const {authenticated} = useSelector(store => store.user);
  const [openModal, setOpenModal] = useState({
    login: false,
    forgotPassword: false,
    resetPassword: false
  });
  const history = useHistory();
  const location = useLocation();
  const height = useFullPageHeight();
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  useEffect(() => {
    if (location.pathname === '/login') {
      handleLoginOpen();
    }
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      handleResetPasswordOpen();
    }
  }, [location])

  const handleLoginOpen = () => {
    setOpenModal({
      login: true,
      forgotPassword: false,
      resetPassword: false
    });
  }

  const handleLoginClose = () => {
    setOpenModal(state => ({...state, login: false}));
  }

  const handleForgotPasswordOpen = () => {
    setOpenModal({
      login: false,
      forgotPassword: true,
      resetPassword: false
    });
  }

  const handleForgotPasswordClose = () => {
    setOpenModal(state => ({...state, forgotPassword: false}));
  }

  const handleResetPasswordOpen = () => {
    setOpenModal({
      login: false,
      forgotPassword: false,
      resetPassword: true
    });
  }

  const handleResetPasswordClose = () => {
    setOpenModal(state => ({...state, resetPassword: false}));
  }

  return (
    <StaticPageWrapper
      sx={{display: 'flex', flexDirection: 'column', minHeight: height, height: height, p: 0, position: 'relative'}}
      hideMobileNav
      footerStyles={{pb: {xs: 0, sm: 2}}}
    >
      <Box sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        '& > video, & > img': {width: '100%', height: '100%', objectFit: 'cover'},
        '&:after': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.4)'
        }
      }}>
        {!isSafari ? (
          <video id="video" controls preload="metadata" autoPlay="autoplay" playsInline muted="on"
                 className="fullscreen-video" loop="loop">
            <source src='https://thepartyclub.fra1.cdn.digitaloceanspaces.com/bg-video.mp4' type="video/mp4" />
          </video>
        ) : (
          <img id="img" autoPlay="autoplay" preload="metadata" playsInline muted="on" className="fullscreen-video"
               loop="loop" src='https://thepartyclub.fra1.cdn.digitaloceanspaces.com/bg-video.mp4' alt="Poster for the video" />
        )}

      </Box>
      <Container maxWidth={'xl'} sx={{maxWidth: 1320, display: 'flex', flexGrow: 1}}>
        <Box sx={{
          mx: 'auto',
          pt: {xs: 0, md: 4},
          maxWidth: 1320,
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
          <Stack
            direction={{xs: 'column', sm: 'row'}}
            justifyContent={'center'}
            spacing={{xs: 3, sm: 6}}
            sx={{mt: 4}}
            style={!authenticated ? {} : { display: "none" }}
          >
            <Button
              variant="contained"
              sx={{
                height: 50,
                width: {xs: '100%', md: 300},
                '&.MuiButton-contained': {
                  background: '#fff',
                  color: '#0E1D31',
                  textTransform: 'uppercase',
                  '&.Mui-disabled': {
                    opacity: .3,
                  },
                  '&:hover': {
                    color: '#fff !important',
                    background: '#0E1D31'
                  },
                },
              }}
              onClick={() => history.push('/join')}
            >
              Sign up
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{height: 50, width: {xs: '100%', md: 300}}}
              onClick={handleLoginOpen}
            >
              LOGIN
            </Button>
          </Stack>
          <SignIn open={openModal.login} onClose={handleLoginClose} onOpenForgotPasswordModal={handleForgotPasswordOpen}/>
          <ForgotPasswordDialog open={openModal.forgotPassword} onClose={handleForgotPasswordClose} />
          <ResetPasswordDialog open={openModal.resetPassword} onClose={handleResetPasswordClose} onOpenLogin={handleLoginOpen} />
        </Box>
      </Container>
    </StaticPageWrapper>
  );
};

export default Home;
